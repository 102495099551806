import React from 'react'

import facebook from './social-facebook.svg'
import twitter from './social-twitter.svg'
import linkedIn from './social-linkedin.svg'
import youtube from './social-youtube.svg'
import instagram from './social-instagram.png'
import './style.scss'

export default props => (
    <div id='social' className='social-media-section'>
        <a target='_blank' href='https://www.facebook.com/MyProofofInsurance/'>
            <img src={facebook} />
        </a>
        <a target='_blank' href='https://www.linkedin.com/company/10008658/'>
            <img src={linkedIn} />
        </a>
        <a target='_blank' href='https://twitter.com/myproofofins'>
            <img src={twitter} />
        </a>
        <a
            target='_blank'
            href='https://www.youtube.com/channel/UCWMwJOsIlIC3lGXwfEV99AA'
        >
            <img src={youtube} />
        </a>
        <a target='_blank' href='https://www.instagram.com/csio_canada/'>
            <img src={instagram} />
        </a>
    </div>
)
