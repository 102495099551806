const menu = {
	en: {
		what: {
			id: 'about',
			title: 'What is My Proof of Insurance',
		},
		how: {
			id: 'how',
			title: 'How It Works',
		},
		wallets: {
			id: 'wallets',
			title: 'Mobile Wallet',
		},
		privacy: {
			id: 'privacy',
			title: 'Privacy',
		},
		getIt: {
			id: 'get-it',
			title: 'Get It',
		},
		testimonials: {
			id: 'testimonials',
			title: 'Testimonials',
		},
		faq: {
			id: 'FAQ',
			title: 'FAQ',
		},
		contact: {
			id: 'contact',
			title: 'Contact Us',
		},
		about: {
			id: 'about',
			title: 'About',
		},
		langToggleText: 'FR',
		langToggleLink: 'https://www.mapreuvedassurance.ca/',
	},
	fr: {
		what: {
			id: 'quoi',
			title: 'Qu’est-ce que c’est?',
		},
		how: {
			id: 'fonctionnement',
			title: 'Fonctionnement',
		},
		wallets: {
			id: 'portefeuille',
			title: 'Portefeuille numérique',
		},
		privacy: {
			id: 'vie-privee',
			title: 'Vie privée',
		},
		getIt: {
			id: 'obtenir',
			title: 'Obtenez-la',
		},
		testimonials: {
			id: 'temoignages',
			title: 'Témoignages',
		},
		faq: {
			id: 'FAQ',
			title: 'FAQ',
		},
		contact: {
			id: 'nous-joindre',
			title: 'Nous joindre',
		},
		about: {
			id: 'a-propos',
			title: 'À propos',
		},
		langToggleText: 'EN',
		langToggleLink: 'https://www.myproofofinsurance.ca',
	},
}
const hero = {
	en: {
		introducing: 'YOUR INSURANCE DOCUMENTS. AT YOUR FINGERTIPS',
		name: 'My Proof of Insurance',
		intro:
			'Canada’s leading solution for securely receiving your insurance documents electronically.',
		intro2:
			'Leave bulky paper behind and access your documents anytime, anywhere  – even on your mobile wallet.',
		getIt: 'Get It',
		how: 'How It Works',
		watch: 'WATCH',
	},
	fr: {
		introducing: 'LA PREUVE EST DANS LE TÉLÉPHONE',
		name: 'Ma preuve d’assurance',
		intro:
			'La meilleure solution offerte au Canada pour recevoir en toute sécurité vos documents d’assurance par voie électronique.',
		intro2:
			'Laissez derrière vous les piles de papier encombrantes et accédez à vos documents en tout temps, et partout – même dans votre portefeuille mobile.',
		getIt: 'Obtenez-la',
		how: 'Fonctionnement',
		watch: 'REGARDER',
	},
}

const what = {
	en: {
		what: 'What is',
		title: 'What Is My Proof of Insurance?',
		text1: `allows you to receive
        your insurance documents for home, auto
        and business from your insurance provider
        over email and save them to your computer and
        mobile device.`,
		text2: `You can easily store your auto insurance card
        (eSlip) in your smartphone’s mobile wallet.`,
		statGreenText1: '3 out of 4',
		statText1: `Canadian drivers currently use a mobile wallet.`,
		statGreenText2: 'most preferred choice',
		statText2a: `The mobile wallet is the`,
		statText2b:
			'among Canadians to store their electronic proof of auto insurance (eSlip).',
	},
	fr: {
		what: `Qu'est-ce que`,
		title: 'Qu’est-ce que Ma preuve d’assurance?',
		text1: `vous permet d’obtenir vos documents d’assurance habitation, d’assurance automobile ou d’assurance commerciale de votre fournisseur d'assurance par courriel et de les sauvegarder dans votre ordinateur ou votre appareil mobile.`,
		text2:
			'Vous pouvez facilement ranger votre carte d’assurance automobile (eSlip) dans le portefeuille numérique de votre téléphone intelligent.',
		statGreenText1: 'Trois conducteurs canadiens sur quatre ',
		statText1: 'utilisent actuellement un portefeuille mobile.',
		statGreenText2: 'Le portefeuille mobile est la ',
		statText2a: '',
		statText2b:
			'solution que les Canadiens privilégient pour entreposer leur preuve d’assurance automobile électronique (eSlip).',
	},
}

const benefits = {
	en: {
		title: 'Benefits of',
		onTheGo: {
			title: 'On the go',
			text:
				'Access your insurance documents anytime, anywhere — even offline.',
		},
		easy: {
			title: 'Easy',
			text: 'No user names, no passwords, no hassle.',
		},
		secure: {
			title: 'Private & Secure',
			text: 'Bank-grade security to protect personal information.',
		},
		free: {
			title: 'Free',
			text: 'No cost to use.',
		},
		paperless: {
			title: 'Paperless',
			text: `Reduce environmental
            impact by accessing
            documents online.`,
		},
	},
	fr: {
		title: 'Les avantages de',
		onTheGo: {
			title: 'Pratique',
			text:
				'Accédez à vos documents d’assurance en tout temps, où que vous soyez, même sans connexion Internet.',
		},
		easy: {
			title: 'Facile',
			text:
				'Pas de nom d’utilisateur, pas de mot de passe, pas de tracas.',
		},
		secure: {
			title: 'Privé et sécurisé',
			text:
				'Niveau de protection de vos renseignements personnels comparable à celui d’une banque.',
		},
		free: {
			title: 'Service gratuit',
			text: 'Aucun coût d’utilisation.',
		},
		paperless: {
			title: 'Sans papier',
			text: `Réduit l’impact environnemental en permettant un accès numérique aux documents.`,
		},
	},
}

const how = {
	en: {
		title: 'How to add your eSlips',
		stepsTitle: 'Get your eSlip in 3 easy steps!',
	},
	fr: {
		title: 'Comment obtenir votre eSlip',
		stepsTitle: 'Obtenez votre eSlip en trois étapes faciles!',
	},
}

const wallets = {
	en: {
		title: 'Mobile Wallet',
	},
	fr: {
		title: 'Portefeuille numérique',
	},
}

const privacy = {
	en: {
		title: 'Privacy & Security',
		apple: 'How to enable this privacy feature.',
		android: 'How to enable this privacy feature.',
		back: 'Back to General Privacy & Security',
		appleTitle: 'Privacy & Security on your iPhone',
		androidTitle: 'Privacy & Security on your Android',
	},
	fr: {
		title: 'Protection des renseignements personnels et sécurité',
		apple: 'Comment activer cette fonction de confidentialité.',
		android: 'Comment activer cette fonction de confidentialité.',
		back:
			'Retour aux Dispositions générales sur la protection de la vie privée et la sécurité',
		appleTitle:
			'Protection des renseignements personnels et sécurité sur votre iPhone',
		androidTitle:
			'Protection des renseignements personnels et sécurité sur votre Android',
	},
}
const getIt = {
	en: {
		title: 'Get My Proof of Insurance',
		paragraph:
			'My Proof of Insurance is available to consumers across Canada. Contact your insurance provider to inquire today!',
	},
	fr: {
		title: 'Pour obtenir Ma preuve d’assurance',
		paragraph:
			'Ma preuve d’assurance est offerte aux consommateurs partout au Canada. Communiquez avec votre fournisseur d’assurance pour vous renseigner dès aujourd’hui!',
	},
}

const testimonials = {
	en: {
		title: 'Testimonials',
		contents: [
			{
				text:
					'It’s great having the auto insurance cards for my car and motorcycle in my digital wallet – no checking to make sure I have the right one anymore!',
				person: 'Jennifer, 41, Nova Scotia',
			},
			{
				text:
					'When my insurance provider offered to send my policy documents electronically, of course I said yes. Who needs more paper? I might finally be able to get rid of my filing cabinet.',
				person: 'Carlos, 34, British Columbia',
			},
			{
				text:
					'So easy. All I had to do was give my insurance provider my email address. Why would anyone want the paper copy when this is available?',
				person: 'Aaron, 28, Quebec',
			},
			{
				text:
					'The convenience is outstanding. It’s so easy to add the auto insurance card in my phone’s digital wallet – no more expired paper cards in my glove compartment!',
				person: 'Dana, 53, Ontario',
			},
			{
				text:
					'This idea is long overdue. Insurance, welcome to the 21st century!',
				person: 'Yasmin, 59, Alberta',
			},
		],
	},
	fr: {
		title: 'Témoignages',
		contents: [
			{
				text:
					'C’est formidable d’avoir les cartes d’assurance automobile pour ma voiture et ma motocyclette dans mon portefeuille numérique – je n’ai plus à vérifier si j’ai la bonne!',
				person: 'Jennifer, 41, Nouvelle-Écosse',
			},
			{
				text:
					'Lorsque mon fournisseur d’assurance m’a offert de m’envoyer mes documents d’assurance par voie électronique, j’ai accepté sans hésiter. Qui a besoin de plus de papier? Je pourrai peut être enfin me débarrasser de mon classeur.',
				person: 'Carlos, 34, Colombie-Britannique',
			},
			{
				text:
					'C’est tellement facile. J’ai simplement donné mon adresse électronique à mon courtier. Pourquoi voudrait-on une copie papier quand il existe une autre option?',
				person: 'Aaron, 28, Québec',
			},
			{
				text:
					'C’est vraiment pratique. Il est si facile d’ajouter la carte d’assurance automobile au portefeuille numérique de mon téléphone – plus de cartes papier expirées dans ma boîte à gants!',
				person: 'Dana, 53, Ontario',
			},
			{
				text:
					'Il était temps. Le monde de l’assurance fait son entrée dans le XXIe siècle!',
				person: 'Yasmin, 59, Alberta',
			},
		],
	},
}

const faq = {
	en: {
		title: 'Frequently Asked Questions',
		contents: [
			{
				heading: 'What is My Proof of Insurance?',
				text: `My Proof of Insurance allows you to receive your insurance documents for home, auto and business from your insurance provider over email and save them to your computer, mobile device or digital wallet.
                    <br/><br/>
                    You can easily store your electronic auto insurance card (eSlip) in your smartphone’s digital wallet
                    `,
			},
			{
				heading:
					'What kinds of insurance documents can I receive through My Proof of Insurance?',
				text:
					'You can receive any type of document currently issued from your insurance provider for home, auto and business – even your auto insurance card.',
			},
			{
				heading:
					'Is My Proof of Insurance available for all types of vehicles (e.g. motorcycles, trucks)?',
				text: 'Yes, it can be used for all types of vehicles. ',
			},
			{
				heading: 'How do I receive my documents?',
				text:
					'You will receive an email from My Proof of Insurance with your policy documents attached. If an auto policy is attached, you will also receive a link to add your auto insurance card to your smartphone’s digital wallet.',
			},
			{
				heading: 'What if I don’t have a smartphone?',
				text:
					'You can still enjoy the convenience of receiving insurance policy documents by email through My Proof of Insurance, and saving them to your computer or tablet for future access.',
			},
			{
				heading:
					'Can I use my electronic auto insurance card in place of the paper copy?',
				text:
					'Approval for electronic auto insurance cards can vary between provinces and territories. Contact your insurance provider for details.',
			},
			{
				heading:
					'Is the paper version of the auto insurance card being eliminated?',
				text:
					'No. The paper version of the insurance card will continue to be available from insurance providers. Policyholders who choose to receive an electronic auto insurance card always have the option of also requesting a paper version.',
			},
			{
				heading: 'How do I access my electronic auto insurance card?',
				text:
					'Simply open your smartphone’s digital wallet and select your auto insurance card to view.',
			},
			{
				heading:
					'Can I share my electronic auto insurance card to others, such as if a friend or family member uses my vehicle with consent?',
				text:
					'Yes. Using the share pass functionality on the digital wallet allows you to easily share your electronic auto insurance card with other drivers who also have a smartphone.',
			},
			{
				heading: 'Is My Proof of Insurance secure?',
				text:
					'Yes. All My Proof of Insurance emails are sent using secure Transport Layer Security (TLS) encryption, and auto insurance cards are stored using digital wallet technology trusted by all major Canadian banks.',
			},
			{
				heading:
					'Will anyone who is viewing the electronic auto insurance card be able to access other information or content on my smartphone?',
				text:
					'Your smartphone allows you to lock your screen when displaying your auto insurance card, restricting access to other apps, personal information, and blocking notifications of calls and text messages. See our <a href="/#privacy">Privacy</a> section to learn how to lock the screen on your device.',
			},
			{
				heading: 'How much does My Proof of Insurance cost?',
				text: 'It’s free!',
			},
			{
				heading: 'Why is it free?',
				text:
					'My Proof of Insurance is funded jointly by insurance providers across Canada as a single, unified solution to send consumers their home, auto and business insurance policy documents, including auto insurance cards, electronically.',
			},
			{
				heading:
					'Do I need the Internet to view my electronic auto insurance card?',
				text:
					'Once you have added your electronic auto insurance card to your digital wallet, you can access it anytime, anywhere — even offline. There is no internet connection required.',
			},
			{
				heading: 'I’m sold. How do I get it?',
				text:
					'My Proof of Insurance is made available to consumers through their insurance provider. Contact your provider to have insurance documents sent electronically with My Proof of Insurance.',
			},
		],
	},
	fr: {
		title: `FAQ sur Ma preuve d'assurance`,
		contents: [
			{
				heading: 'Qu’est-ce que Ma preuve d’assurance?',
				text: `Ma preuve d’assurance vous permet d’obtenir vos documents d’assurance habitation, d’assurance automobile ou d’assurance commerciale de votre fournisseur d’assurance par courriel et de les sauvegarder dans votre ordinateur, votre appareil mobile ou votre portefeuille numérique.
                    <br/><br/>
                    Vous pouvez facilement stocker votre carte d’assurance automobile électronique (eSlip) dans le portefeuille numérique de votre téléphone intelligent`,
			},
			{
				heading:
					'Quels types de documents d’assurance puis je recevoir au moyen de Ma preuve d’assurance?',
				text:
					'Vous pouvez recevoir n’importe quel type de document actuellement émis par votre fournisseur d’assurance pour votre maison, votre automobile et votre entreprise – même votre carte d’assurance automobile.',
			},
			{
				heading:
					'Ma preuve d’assurance est elle disponible pour tous les types de véhicules (p. ex., motocyclettes, camions)?',
				text:
					'Oui, elle peut être utilisée pour tous les types de véhicules.',
			},
			{
				heading: 'Comment puis je recevoir mes documents?',
				text:
					'Vous recevrez un courriel de Ma preuve d’assurance, auquel seront joints vos documents d’assurance. Si une police d’assurance automobile est jointe, vous recevrez également un lien pour ajouter votre carte d’assurance automobile au portefeuille numérique de votre téléphone intelligent.',
			},
			{
				heading: 'Et si je n’ai pas de téléphone intelligent?',
				text:
					'Vous pouvez quand même recevoir vos documents d’assurance par courriel au moyen de Ma preuve d’assurance et les sauvegarder dans votre ordinateur ou votre tablette pour y accéder plus tard.',
			},
			{
				heading:
					'Puis je utiliser ma carte d’assurance automobile électronique au lieu de la copie papier?',
				text:
					'L’approbation des cartes d’assurance automobile électroniques peut varier selon la province ou le territoire. Communiquez avec votre fournisseur d’assurance pour obtenir des détails.',
			},
			{
				heading:
					'La version papier de la carte d’assurance automobile est elle éliminée?',
				text:
					'Non. La version papier de la carte d’assurance continuera d’être disponible auprès des fournisseurs d’assurance. Les titulaires de police qui choisissent de recevoir une carte d’assurance automobile électronique ont toujours la possibilité de demander également une version papier.',
			},
			{
				heading:
					'Comment puis-je accéder à ma carte d’assurance automobile électronique?',
				text:
					'Vous n’avez qu’à ouvrir le portefeuille numérique de votre téléphone intelligent et sélectionner votre carte d’assurance automobile pour y accéder.',
			},
			{
				heading:
					'Puis-je partager ma carte d’assurance automobile électronique avec d’autres personnes, par exemple si un ami ou un membre de ma famille utilise mon véhicule avec mon consentement?',
				text:
					'Oui. L’utilisation de la fonction de partage dans le portefeuille numérique vous permet de partager facilement votre carte d’assurance automobile électronique avec d’autres conducteurs qui ont également un téléphone intelligent. ',
			},
			{
				heading: 'Ma preuve d’assurance est-elle sécurisée?',
				text:
					'Oui. Tous les courriels liés à Ma preuve d’assurance sont envoyés au moyen du protocole de chiffrement sécurisé Transport Layer Security (TLS), et les cartes d’assurance automobile sont stockées à l’aide de la technologie du portefeuille numérique mobile qui est utilisée en toute confiance par toutes les grandes banques canadiennes.',
			},
			{
				heading:
					'Est-ce que quiconque consulte la carte d’assurance automobile électronique pourra accéder à d’autres renseignements ou contenus sur mon téléphone intelligent?',
				text:
					'Votre téléphone intelligent vous permet de verrouiller votre écran lors de l’affichage de votre carte d’assurance automobile, de restreindre l’accès à d’autres applications et à vos renseignements personnels, et de bloquer les avis d’appels et de messages textes. Consultez la section Protection des renseignements personnels pour apprendre comment verrouiller l’écran de votre appareil.',
			},
			{
				heading: 'Combien coûte Ma preuve d’assurance?',
				text: 'C’est gratuit!',
			},
			{
				heading: 'Pourquoi est-ce gratuit?',
				text:
					'L’initiative Ma preuve d’assurance est financée conjointement par des fournisseur d’assurances de toutes les régions du Canada comme solution unique et uniforme pour envoyer aux consommateurs par voie électronique leurs documents d’assurance habitation, d’assurance automobile et d’assurance commerciale, y compris les cartes d’assurance automobile.',
			},
			{
				heading:
					'Ai-je besoin d’Internet pour consulter ma carte d’assurance automobile électronique?',
				text:
					'Une fois que vous avez ajouté votre carte d’assurance automobile électronique à votre portefeuille numérique, vous pouvez y accéder en tout temps, n’importe où, même hors ligne. Aucune connexion Internet n’est requise.',
			},
			{
				heading: 'Ça m’intéresse. Comment puis-je l’obtenir?',
				text:
					'Ma preuve d’assurance est offerte aux consommateurs par l’entremise de leur fournisseur d’assurance. Communiquez avec votre fournisseur d’assurance pour que vos documents d’assurance vous soient envoyés par voie électronique par l’entremise de Ma preuve d’assurance.',
			},
		],
	},
}

const contact = {
	en: {
		title: 'Contact Us',
		questionsTitle: 'Questions about your eSlip or insurance?',
		questionsText:
			'My Proof of Insurance is available to consumers through their insurance provider. <br/> <br/> Please contact your provider for more information about My Proof of Insurance or any questions regarding your insurance policy.',
		feedbackTitle: 'Feedback about My Proof of Insurance?',
		feedbackText:
			'Have feedback about the My Proof of Insurance solution that you’d like to share?',
		email:
			'Email: <a href="mailto:feedback@myproofofinsurance.ca?subject=Feedback: My Proof of Insurance">feedback@myproofofinsurance.ca</a>',
		formText: 'You can also fill out the form below.',
		socialTitle: 'Want to connect with us?',
		socialText:
			'Follow our social media channels for tips and information on eSlips!',
		text:
			'My Proof of Insurance is available to consumers through their insurance provider. Please contact your provider for more information about My Proof of Insurance or any questions regarding your insurance policy. <br/><br/> Have feedback about My Proof of Insurance that you’d like to share? Email us at <a href="mailto:feedback@myproofofinsurance.ca?subject=Feedback: My Proof of Insurance"> feedback@myproofofinsurance.ca</a> or fill the form below.',
		sentText: 'Your comments have been successfully submitted.  Thank you!',
		errorText:
			'We were not able to send your comments, please make sure you have completed the form correctly or send us your comments at the above email.',
		inputName: 'Name',
		inputEmail: 'Email',
		inputComments: 'Comments',
		submit: 'Submit',
	},
	fr: {
		title: 'Communiquez avec nous',
		questionsTitle:
			'Des questions au sujet de votre eSlip ou de votre assurance?',
		questionsText: `Ma preuve d’assurance est offerte aux consommateurs par leur fournisseur d’assurance.
            <br/><br/>
            Veuillez communiquer avec votre fournisseur d’assurance, comme votre courtier d’assurances, pour obtenir de plus amples renseignements sur Ma preuve d’assurance ou toute question concernant votre police d’assurance.
            `,
		feedbackTitle: 'Des commentaires sur Ma preuve d’assurance?',
		feedbackText:
			'Souhaitez-vous faire part de vos commentaires sur Ma preuve d’assurance?',
		email: `Courriel: <a href="mailto:feedback@myproofofinsurance.ca?subject=Commentaires: Ma preuve d'assurance">feedback@myproofofinsurance.ca</a>`,
		formText: 'Vous pouvez également remplir le formulaire ci dessous.',
		socialTitle: 'Vous voulez communiquer avec nous?',
		socialText:
			'Suivez nous sur les médias sociaux pour obtenir des conseils et de l’information sur les eSlips!',
		text:
			'Ma preuve d’assurance est offerte aux consommateurs par leur fournisseur d’assurance. Veuillez communiquer avec votre fournisseur d’assurance pour obtenir de plus amples renseignements sur Ma preuve d’assurance ou toute question concernant votre police d’assurance. Souhaitez-vous partager des commentaires sur Ma preuve d’assurance? Envoyez-nous un courriel à l’adresse suivante : <a href="mailto:commentaires@mapreuvedassurance.ca?subject=Commentaires: Ma preuve d’assurance">commentaires@mapreuvedassurance.ca</a>',
		sentText: 'Vos commentaires ont été envoyés avec succès. Merci!',
		errorText: `Nous n'avons pas pu envoyer vos commentaires, veuillez vous assurer que le formulaire est complet ou envoyez-nous vos commentaires au courriel ci-haut.`,
		inputName: 'Nom',
		inputEmail: 'Courriel',
		inputComments: 'Commentaires',
		submit: 'Soumettre',
	},
}

const about = {
	en: {
		title: 'About',
		text:
			'My Proof of Insurance is funded jointly by insurance providers across Canada as a single, unified solution to send consumers their home, auto and business insurance policy documents, including auto insurance cards, electronically.',
	},
	fr: {
		title: 'À propos',
		text:
			'L’initiative Ma preuve d’assurance est financée conjointement par des fournisseurs d’assurance de toutes les régions du Canada comme solution unique et uniforme pour envoyer aux consommateurs par voie électronique leurs documents d’assurance habitation, d’assurance automobile et d’assurance commerciale, y compris les cartes d’assurance automobile.',
	},
}

const layout = {
	en: {
		footer:
			'Copyright 2020 Centre for Study of Insurance Operations (CSIO). All rights reserved.',
		footerUrl: 'https://www.csio.com/',
	},
	fr: {
		footer:
			'2020 Centre for Study of Insurance Operations (CSIO). Tous droits réservés.',
		footerUrl: 'https://www.csio.com/fr',
	},
}

const seo = {
	en: {
		home: {
			title: 'My Proof of Insurance',
		},
		androidPrivacy: {
			title: 'Android Privacy',
		},
		applePrivacy: {
			title: 'Apple Privacy',
		},
		title: 'My Proof of Insurance',
		description:
			'Say good-bye to paper! With My Proof of Insurance, get policy documents for home, auto and business from your insurance provider over email and save them to your computer or mobile device.',
		keywords:
			'Proof of Insurance, My Proof of Insurance, Mobile, Smartphones, Smartphone, Insurance, Insured, Liability, Slip, Liability Slip, Pink Slip',
		trackingId: 'UA-28099006-2',
		url: 'https://www.myproofofinsurance.ca',
		image:
			'https://www.myproofofinsurance.ca/static/devices_final2.c92d6662.png',
	},
	fr: {
		home: {
			title: 'My Proof of Insurance',
		},
		androidPrivacy: {
			title: 'Android Privacy',
		},
		applePrivacy: {
			title: 'Apple Privacy',
		},
		title: 'Ma preuve d’assurance',
		description:
			'Dites adieu au papier! Avec Ma preuve d’assurance, obtenez vos documents d’assurance habitation, d’assurance automobile ou d’assurance commerciale de votre fournisseur d’assurance par courriel et sauvegardez-les dans votre ordinateur ou votre appareil mobile.',
		keywords:
			'preuve assurance, preuve d’assurance, Ma preuve assurance, Mobile, Téléphone Intelligent, Assurance, Assuré, Responsabilité, Carte assurance, Carte assurance auto, Carte assurance',
		trackingId: 'UA-28099006-3',
		url: 'https://www.mapreuvedassurance.ca',
		image:
			'https://www.mapreuvedassurance.ca/static/devices_final_fr2.77ff2172.png',
	},
}

const disclaimer = {
	en: {
		title: 'Disclaimer',
		text: `My Proof of Insurance is a service provided to your insurance provider.  We enable your insurance provider to send you insurance documents electronically.  My Proof of Insurance is not an insurance provider – as such, we cannot process coverage or other changes to your insurance, or answer questions about your insurance.  Please contact your insurance provider for changes to your insurance or questions about your insurance.`,
	},
	fr: {
		title: 'Avis de non-responsabilité',
		text:
			'Ma preuve d’assurance est un service fourni à votre fournisseur d’assurance. Nous permettons à votre fournisseur d’assurance de vous envoyer des documents d’assurance par voie électronique. Ma preuve d’assurance n’est pas un fournisseur d’assurance – nous ne pouvons donc offrir aucune protection ou effectuer aucun autre changement à votre assurance, ni répondre aux questions au sujet de votre assurance. Veuillez communiquer avec votre fournisseur d’assurance si vous souhaitez apporter des modifications à votre assurance ou si vous avez des questions au sujet de votre assurance.',
	},
}

module.exports = {
	menu,
	hero,
	what,
	how,
	wallets,
	privacy,
	getIt,
	testimonials,
	faq,
	contact,
	about,
	layout,
	seo,
	benefits,
	disclaimer,
}
